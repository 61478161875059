<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>UGC COMPLIANCES</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <h3>UGC COMPLIANCES</h3>
                        <!-- <p>
                            <a
                                href="assets/documents/Report_A2021-2022.pdf"
                                target="_blank"
                                >1. Application for Recognition (2021-22 and
                                Onwards)</a
                            >
                        </p>
                        <p>
                            <a
                                href="assets/documents/Report 2022-23.pdf"
                                target="_blank"
                                >2. Application for Recognition (2022-23 and
                                Onwards)</a
                            >
                        </p> -->
                        <p>
                            <a
                                href="assets/documents/PublicNotice.pdf"
                                target="_blank"
                                >1. Public Notice</a
                            >
                        </p>
                        <p>
                            <a
                                href="assets/documents/UGC Portal_OL_2022-23_31.03.2023.pdf"
                                target="_blank"
                                >2. Application for Recognition (2023-24 and
                                Onwards)</a
                            >
                        </p>
                        <p>
                            <a
                                href="assets/documents/ImportantDocuments/Application-2024-25-FEB-2025-OL.pdf"
                                target="_blank"
                                >3. Application for Recognition - Online
                                Programme 2024-25</a
                            >
                        </p>
                        <p>
                            <a
                                href="assets/documents/ImportantDocuments/UNDERTAKING.pdf"
                                target="_blank"
                                >4. Declaration from Registrar</a
                            >
                        </p>
                        <p>
                            <a
                                href="assets/documents/ImportantDocuments/CIQA_Report2018-19.pdf"
                                target="_blank"
                                >5. CIQA 2018-2019</a
                            >
                        </p>
                        <p>
                            <a
                                href="assets/documents/ImportantDocuments/2019-20_ciqareport_1319.pdf"
                                target="_blank"
                                >6. CIQA 2019-2020</a
                            >
                        </p>
                        <p>
                            <a
                                href="assets/documents/ImportantDocuments/CIQA-2022-23.pdf"
                                target="_blank"
                                >7. CIQA-2022-23</a
                            >
                        </p>
                        <p>
                            <a
                                href="assets/documents/ImportantDocuments/NAAC_A_Grade_Certificate.pdf"
                                target="_blank"
                                >8. NAAC Certificate</a
                            >
                        </p>
                        <p>
                            <a
                                href="assets/documents/ImportantDocuments/Affidavit_completed_30.03.2023.pdf"
                                target="_blank"
                                >9. Affidavit 2023-24</a
                            >
                        </p>
                        <p>
                            <a
                                href="assets/documents/Video of infrastructure facilities for OL.mp4"
                                target="_blank"
                                >10. Virtual Tour</a
                            >
                        </p>
                        <p>
                            <a
                                href="assets/documents/ImportantDocuments/Administrative_Preparedness.pdf"
                                target="_blank"
                                >11. Declaration of Administrative
                                Arrangement</a
                            >
                        </p>

                        <p>
                            <a
                                href="assets/documents/ImportantDocuments/AdmissionData.pdf"
                                target="_blank"
                                >12. Admission Data</a
                            >
                        </p>
                        <!-- <p>
                            <a
                                href="assets/documents/ImportantDocuments/Territorial Jurisdiction.pdf"
                                target="_blank"
                                >9. Territorial Jurisdiction</a
                            >
                        </p> -->

                        <!-- <p>
                            <a
                                href="assets/documents/ImportantDocuments/Director Appointment Letter.pdf"
                                target="_blank"
                                >8. Director Appointment Letter</a
                            >
                        </p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
